import React, { memo } from "react"
import style from "styles/roadmap.module.css"
import Q1 from './icons/q1'
import Q2 from './icons/q2'
import Q3 from './icons/q3'
import Q4 from './icons/q4'
import Line from './icons/line'
import LineMobile from './icons/lineMobile'
import useLocale from '../../../../hooks/useLocale'
import {useRouter} from 'next/router'

const Roadmap = () => {
	const router = useRouter()
	const {roadmapSection}= useLocale()
	return (
		<div id="roadmap" className={style.roadmap}>
			<div className={style.container}>
				<h2 className={style.title} data-aos-once="true" data-aos="slide-up">{roadmapSection.title}</h2>
				<div className={style.image}>
							<img alt="" className="-mt-[-20px] ml-2 md:-mt-[-10px] md:-ml-[-10px]  .d-none .d-sm-block" src="/image/SUIGAMING-map.png"/>
				</div>		
				{/*
				
				<div className={style.grid}>
					<div className={style.line}>
						<Line width="100%" />
					</div>
					<div className={style.lineMobile}>
						<LineMobile />
					</div>
					<div className={style.item} data-aos-once="true" data-aos="fade-right" data-aos-delay={100}>
						<div className={style.circle}>
							<Q2 width="100%" height="100%" />
						</div>
						<p className={style.subtitle}>2024</p>
						<h3 className={style.description}>{roadmapSection.first}</h3>
						<div className='absolute -mr-12 right-0 top-9 border-2 w-24 border-white/30 5xl:block hidden'></div>
					</div>
					<div className={style.item} data-aos-once="true" data-aos="fade-right" data-aos-delay={200}>
						<div className={style.circle}>
							<Q3 width="100%" height="100%" />
						</div>
						<p className={style.subtitle}>2024</p>
						<h3 className={style.description}>{roadmapSection.second}</h3>
						<div className='absolute -mr-12 right-0 top-9 border-2 w-24 border-white/30 5xl:block hidden'></div>
					</div>
					<div className={style.item} data-aos-once="true" data-aos="fade-right" data-aos-delay={300}>
						<div className={style.circle}>
							<Q4 width="100%" height="100%" />
						</div>
						<p className={style.subtitle}>2024</p>
						<h3 className={style.description}>{roadmapSection.third}</h3>
						<div className='absolute -mr-12 right-0 top-9 border-2 w-24 border-white/30 5xl:block hidden'></div>
					</div>
					<div className={style.item} data-aos-once="true" data-aos="fade-right" data-aos-delay={400}>
						<div className={style.circle}>
							<Q1 width="100%" height="100%" />
						</div>
						<p className={style.subtitle}>2025</p>
						<h3 className={style.description}>{roadmapSection.fourth}</h3>
						<div className='absolute -mr-12 right-0 top-9 border-2 w-24 border-white/30 5xl:block hidden'></div>
					</div>
					<div className={style.item} data-aos-once="true" data-aos="fade-right" data-aos-delay={500}>
						<div className={style.circle}>
							<Q2 width="100%" height="100%" />
						</div>
						<p className={style.subtitle}>2025</p>
						<h3 className={style.description}>{roadmapSection.fifth}</h3>
					</div>
				</div>
				 */}
			</div>
			
			<div className={style.blockBtn} data-aos-once="true" data-aos="flip-down">
				<button onClick={() => window.open('https://blog.metaisland.gg/roadmap', '_blank')}
					className={style.postBtn}>{roadmapSection.button}
				</button>
			</div>
		</div>
	)
}

export default memo(Roadmap)
